:root {
  --amplify-primary-color: #141446;
  --amplify-primary-tint: #137bc0;
  --amplify-primary-shade: #137bc0;
  --container-align: flex-start;
  --amplify-secondary-tint: rgb(19, 123, 192, 1);
}

amplify-authenticator {
  display: flex;
  justify-content: flex-start;
  padding-left: 5%;
  align-items: flex-start !important;
  flex: 1;
  height: 50vh;
}

amplify-authenticator .auth-container {
  align-items: flex-start !important;
}

.recharts-responsive-container {
  padding: 10px;
  width: 100% !important;
  height: 400px !important;
  height: 250px !important;
}

.recharts-wrapper {
  margin-right: auto;
}
